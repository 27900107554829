@import "./colors";

html {
  scroll-behavior: smooth;
}
.input-number-fullsize {
  width: 100% !important;
}

.hidden {
  display: none;
}
// body {
//     font: 16px arial, sans-serif;
// }

// h1,
// h2,
// h3,
// h4,
// h5,
// h6,
// p {
//   margin: 0;
//   padding: 0;
// }

// h1 {
//   font-size: 56px;
//   line-height: 56px;
//   margin-bottom: 20px;
// }

// h2 {
//   font-size: 42px;
//   line-height: 42px;
// }

// h3 {
//   font-size: 36px;
// }

// h4 {
//   color: #000;
//   font-size: 20px;
//   line-height: 28px;
//   font-weight: 300;
// }

// h5 {
//   font-size: 20px;
//   line-height: 28px;
//   font-weight: 300;
// }

// a {
//   color: #031b4e;
//   text-decoration: none;
//   cursor: pointer;

//   &:hover {
//   }
// }

// p {
//   font-size: 18px;
//   line-height: 28px;
//   color: #4a4a4a;
// }

// nav {
//   ul {
//     margin: 0;
//     padding: 0;
//     li {
//       list-style: none;
//       display: inline-block;
//       a {
//         color: #000;
//         &:hover {
//           color: $brand-secondary;
//         }
//       }
//     }
//   }
// }

// form {
//   display: flex;
//   flex-direction: column;
//   label {
//     margin-bottom: 14px;
//   }
//   input {
//     outline: 0;
//     height: 48px;
//     border: 1px solid #dadada;
//     color: #2d2d2d;
//     padding: 0 16px;
//     background: #fff;
//     border-radius: 5px;
//     font-size: 16px;
//     transition: 0.2s;
//     margin-bottom: 14px;

//     &:focus {
//       border: 1px solid $brand-secondary;
//     }

//     &[type="checkbox"] {
//       margin-right: 12px;
//       margin-bottom: 0;
//     }
//   }
// //   span {
// //     background-color: #f8d7da;
// //     color: #721c24;
// //     margin-bottom: 18px;
// //     padding: 10px 14px;
// //     border-radius: 5px;
// //     text-align: left;
// //   }
// }

// button,
// .btn {
//   display: block;
//   height: 48px;
//   background-color: $brand-secondary;
//   border: solid 1px $brand-secondary;
//   color: #fff;
//   text-align: center;
//   padding: 0 16px;
//   border-radius: 5px;
//   vertical-align: middle;
//   white-space: nowrap;
//   line-height: 48px;
//   font-weight: 600;
//   cursor: pointer;
//   outline: 0 !important;
//   transition: 0.2s;

//   &:hover {
//     background-color: darken($brand-secondary, 8);
//   }

//   &.rounded {
//     border-radius: 24px;
//     padding: 0 24px;
//   }
// }

// section {
//   .title {
//     font-size: 36px;
//     line-height: 40px;
//     font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI",
//       Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
//       "Segoe UI Symbol";
//     font-weight: 500;
//     margin-bottom: 36px;
//   }
// }

// #description,
// #how-it-works,
// #about-us,
// #pricing,
// #faq {
//   padding-top: 80px;
//   padding-bottom: 80px;
// }

// #description {
// }

// #how-it-works {
// }

// #about {
//   p {
//     font-size: 20px;
//     line-height: 1.618;
//   }
// }

// .step {
//   width: 32px;
//   height: 32px;
//   border-radius: 16px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   background: $brand-secondary;
//   margin-right: 14px;
//   span {
//     color: #fff;
//   }
// }

// .step-icon {
//   display: block;
//   width: 200px;
//   height: 200px;
//   margin: 0 auto;
//   background-repeat: no-repeat;
//   background-position: center;
//   background-size: contain;
//   margin-bottom: 20px;
// }

// .column-center-vertically {
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
// }

// .text-left {
//   text-align: left;
// }

// .text-center {
//   text-align: center;
// }

// .text-right {
//   text-align: right;
// }

// .text-highlight {
//   color: darken($brand-secondary, 10%);
//   font-weight: bold;
// }

// .btn-underline {
//   background: #fff;
//   display: block;
//   color: $brand-secondary;
//   border: none;

//   &:hover {
//     background: #fff;
//     color: $brand-secondary;
//     text-decoration: underline;
//   }
// }

// .full-width {
//   width: 100%;
// }

// @media only screen and (max-width: 600px) {
//   #description,
//   #how-it-works,
//   #about,
//   #pricing,
//   #faq {
//     padding: 42px 28px;
//   }
// }
