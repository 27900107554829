@import 'src/scss/partials/colors';

.functionality-card {
  // display: flex;
  padding: 30px;
  // max-height: 400px;
  align-items: stretch;
  .step {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    .stepTitle {
      margin-left: 20px;
    }
    .icon-circle {
      display: flex;
      justify-content: center;
      width: 25px;
      height: 25px;
      border-radius: 100px;
      background: $brand-secondary;
      .icon-text {
        display: flex;
        width: 25px;
        height: 25px;
        align-self: center;
        justify-content: center;
        color: #ffffff;
      }
    }
  }
  .images {
    display: flex;
    width: 100%;
    max-height: 220px;
    margin: 20px 0;
  }

  .step-description {
    font-size: 1.2em;
    margin-top: 30px;
  }
}
