@import "../../scss/partials/colors";

.signup-section {
  background-color: #f5f5f5;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 50px;
  .header {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .form-section {
    background-color: white;
    padding: 10px;
    width: 60%;
    .if-require::before {
      display: inline-block;
      margin-right: 4px;
      color: #ff4d4f;
      font-size: 14px;
      line-height: 1;
      content: "*";
    }
    .inputGroup {
      // display: inline;
      line-height: 1.5715;
      label {
        width: 100%;
        padding: 0 0 8px;
      }
      .select {
        margin-bottom: 24px;
      }
      // margin-bottom: 24px;
    }
  }
}
@media screen and(max-width: 450px) {
  .signup-section {
    .form-section {
      width: 90%;
    }
  }
}

// form {
//     background: #FFF;
//     padding: 24px 0 24px 0;
//     // -webkit-box-shadow: 0px 2px 4px -2px rgba(0,0,0,0.5);
//     // -moz-box-shadow: 0px 2px 4px -2px rgba(0,0,0,0.5);
//     // box-shadow: 0px 2px 4px -2px rgba(0,0,0,0.5);
//     input {
//         margin-bottom: 18px;
//     }
// }

// #signup {
//     background: #f5f5f5;
//     height: auto;
//     padding: 60px 0 60px 0;
//     .form-wrap {
//         background: #FFF;
//         padding: 24px 24px 32px 24px;
//         h3 {
//             padding-top: 24px;
//             text-align: left;
//         }
//         .radio-input {
//             display: flex;
//             align-items: center;
//             margin-bottom: 40px;
//             input[type="radio"]{
//                 margin: 0 12px 0 0;
//                 height: 18px;
//             }
//         }
//         .css-2b097c-container {
//             margin-bottom: 12px;
//         }
//         .css-yk16xz-control, .css-yk16xz-control input, .css-1hwfws3, .css-w8afj7-Input {
//             height: 48px;
//             margin: 0;
//             line-height: 48px;
//         }
//         .css-1hwfws3 {
//             padding-top: 0;
//             padding-bottom: 0;
//         }
//         .css-1uccc91-singleValue{
//             border: none;
//         }
//         .css-1okebmr-indicatorSeparator{
//             padding: 0;
//         }
//         .css-1pahdxg-control {
//             border: 1px solid $brand-secondary;
//             box-shadow: none;
//         }
//         .css-26l3qy-menu {
//             text-align: left;
//         }
//     }
// }
