.container-external-links {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    h6 {
        font-size: 30px;
    }
    .container-social-icons { 
        flex-direction: row;
        .social-icons {
            font-size: 50px;
            margin: 10px;
        }
    }  
}